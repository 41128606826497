<template>
  <div>
    <NavBar
      style="max-height: 50px;margin-bottom: 10px;"
      :username="currentUserName"
      :pageName="pageName"
    ></NavBar>

    <v-app>
      <div style="background-color: #f5faff; height: 92vh !important;">
         <!-- Cancal appointment Dialog Start  -->
      <div>
        <v-row justify="center">
          <v-dialog v-model="dialog" persistent max-width="300">
            <v-card>
              <v-card-title style="word-break: normal ; padding: 5%">
                Confirm Reschedule?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="primary"
                  text
                  @click="confirm()"
                  style="background-color: #1467BF;"
                >
                  Reschedule
                </v-btn>
                <v-btn class="error" text @click="notConfirm" style="background-color: #EB5757;">
                  No
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <!-- cancel appointment dialog ends  -->
        <v-row cols="12">
        <v-col cols="2">
        <SideBar style="max-height=50px;" :pageName="pageName"></SideBar>
        </v-col>
          <v-col cols="10">
            <h3
              style="
                text-align: left;
                margin-left: 40px;
                margin-top: 30px;
                font-weight: 800;
                 font-size: 12pt;
                text-transform: uppercase;   letter-spacing: 1px;
              "
            >
              Reschedule Appointment
            </h3>
            <v-card
              style="
                width: 95%;
                margin-left: 40px;
                height: 100%;
                margin-top: 20px;
                height: 70vh;
                overflow: scroll;
                overflow-x: hidden;
                padding: 16px 32px;
              "
            >
              <div
                style="display: flex; flex-direction: row; margin: 10px"
                align="left"
              >
                <div
                  style="width: 100%; display: flex; flex-direction: column"
                  align="left"
                >
                  <div>
                    <div style="display: flex; flex-direction: row">
                      <div style="width: 50%" align="left">
                        <p style="font-size: 10pt; font-weight: 600">
                          Patient Name
                        </p>
                      </div>
                      <div style="width: 50%; font-weight: 800" align="center">
                        <h2>{{ patient_list.booked_for_name }}</h2>
                      </div>
                    </div>
                    <div style="display: flex; flex-direction: row">
                      <div style="width: 50%" align="left">
                        <p
                          style="
                            margin-top: 12px;
                            font-size: 10pt;
                            font-weight: 600;
                          "
                        >
                          Specialization
                        </p>
                      </div>
                      <div style="width: 50%" align="right">
                        <v-select
                          :items="specialist_list"
                          label="Specialization"
                          v-model="specialist_name"
                          background-color="#F4F6FA"
                          style="border-radius: 8px"
                          solo
                          disabled
                        ></v-select>
                      </div>
                    </div>

                    <div style="display: flex; flex-direction: row">
                      <div style="width: 50%" align="left">
                        <p
                          style="
                            margin-top: 12px;
                            font-size: 10pt;
                            font-weight: 600;
                          "
                        >
                          Doctor Name
                        </p>
                      </div>
                      <div style="width: 50%" align="right">
                        <v-select
                          :items="doctor_list"
                          label="Doctor Name"
                          v-model="selected_doctor"
                          solo
                          disabled
                          background-color="#F4F6FA"
                          style="border-radius: 8px"
                        ></v-select>
                      </div>
                    </div>

                    <div style="display: flex; flex-direction: row">
                      <div style="width: 50%" align="left">
                        <p
                          style="
                            margin-top: 12px;
                            font-size: 10pt;
                            font-weight: 600;
                          "
                        >
                          Date Of Appointment
                        </p>
                      </div>
                      <div style="width: 50%" align="right">
                        <v-select
                          background-color="#F4F6FA"
                          solo
                          style="border-radius: 8px"
                          :items="dateRange"
                          item-text="display_date"
                          item-value="date_query"
                          item-disabled="slot_disabled"
                          label="Select Date"
                          v-model="selected_date"
                          @change="getTimeSlot"
                        >
                        </v-select>
                      </div>
                    </div>

                    <div style="display: flex; flex-direction: row">
                      <div style="width: 50%">
                        <p
                          style="
                            margin-top: 12px;
                            font-size: 10pt;
                            font-weight: 600;
                          "
                        >
                          Select Time Slot
                        </p>
                      </div>
                      <div style="width: 50%">
                        <v-select
                          :items="time_slot"
                          item-value="_id"
                          item-disabled="customDisabled"
                          label="Time Slot"
                          item-text="displayTime"
                          v-model="time_slot_model"
                          background-color="#F4F6FA"
                          solo
                          style="border-radius: 8px"
                          return-object
                        ></v-select>
                      </div>
                    </div>

                    <div style="display: flex; flex-direction: row">
                      <div style="width: 50%" align="left">
                        <p
                          style="
                            margin-top: 12px;
                            font-size: 10pt;
                            font-weight: 600;
                          "
                        >
                          Description
                        </p>
                      </div>
                      <div style="width: 50%" align="rigth">
                        <v-textarea
                          background-color="#F4F6FA"
                          solo
                          style="border-radius: 8px"
                          label="Type your description here"
                          v-model="description"
                          placeholder="Enter more details about the appointment"
                          :rules="[
                            (v) =>
                              (v || '').length < 160 ||
                              'Description must be 160 characters or less',
                          ]"
                        ></v-textarea>
                      </div>
                    </div>

                    <div align="center">
                      <v-btn
                        style="width: 20vw;background-color: #1467BF;"
                        color="primary"
                        @click="feeDifferance"
                        v-bind:disabled="
                          isBookSlot ||
                          !(
                            description.length < 160 &&
                            specialist_name &&
                            time_slot_model &&
                            selected_doctor &&
                            selected_date
                            )
                        "
                      >
                        Schedule Appointment
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../navbar.vue";
import SideBar from "../../../views/sideBar.vue";
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
import { handleError } from '../../../utils/utils';

export default {
  name: "ResheduleAppintment",
  data() {
    return {
      dialog:false,
      book_id: "",
      patient_list: "",
      isBookSlot: false,
      specialist_list: [],
      specialist_name: "",
      doctor_list: [],
      selected_doctor: "",
      dateRange: [],
      selected_date: "",
      time_slot: [],
      specialization_id: "",
      time_slot_model: "",
      description: "",
      payment_list: ["PAYMENT PENDING", "PAID", "REFUNDED", "EXPIRED"],
      currentUser: "",
      currentUserName: "",
      user_id: "",
      phoneNumber: "",
      pageName:"rescheduleAppointment",
      items: ["Phone Number", "NRC"],
      search_type: "",
      nrc_number: "",
      selected_doctor_id: null,
    };
  },
  mounted() {
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      var params = this.$route.params;
      this.book_id = params["booking_id"];
      var data = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        bookingId : this.book_id
      };
      axios_auth_instance_admin.post("/adminRescheduleAppointmentPage", data)
      .then(async(successResponse) => {
        this.currentUserName = successResponse.data.admin_data.admin_name;
        this.patient_list = successResponse.data.appointment_data;
        this.specialization_id = this.patient_list.specialization_id;
        this.specialist_name = this.patient_list.specialization_name;
        this.selected_doctor = this.patient_list.doctor_name;
        this.doctor_list.push(this.selected_doctor);
        this.specialist_list.push(this.specialist_name);
        this.selected_doctor_id = this.patient_list.doctor_id;
        for (var i = 0; i < successResponse.data.date_array.length; i++) {
            var strtoDate = new Date(
              successResponse.data.dataConverted[i]
            );
            this.dateRange.push({
              display_date: successResponse.data.date_array[i],
              date_query: successResponse.data.dataConverted[i],
              slot_disabled:
                successResponse.data.buttonshow[i].isDateSlotPresent ==
                "YES"
                  ? false
                  : true,
              day: strtoDate.toLocaleString("en-us", {
                weekday: "short",
              }),
            });
          }
      })
      .catch((errorResponse) => {
        handleError(errorResponse, this.$router, 'adminRescheduleAppointmentPage', 'AdminLogin', 'token');
      });
    }
  },
  components: {
    NavBar,SideBar
  },
  methods: {
    confirm(){
      this.rescehduleAppointment();
      this.dialog= false;
    },
    notConfirm(){
      this.dialog= false;
    },
    feeDifferance(){
      this.dialog=true;
    },
    rescehduleAppointment() {
      this.dialog=false
      this.isBookSlot = true;
      var booking_data = {
        appointmentId: this.book_id,
        appointmentDate: this.selected_date,
        timeSlot: this.time_slot_model.time_slot,
        description: this.description,
        token: this.currentUser,
        typeOfUser: "ADMIN",
      };
      axios
      .post(
        process.env.VUE_APP_BACKEND_URL + "/rescheduleAppointmentTwilio",
        booking_data
      )
      .then((getAppointmentDetailsResponse) => {
        if (getAppointmentDetailsResponse.status == 200) {
          window.alert("Reschedule Appointment Successful");
           this.$router.push({
              name: 'AdminHome'
            });
        }
      })
      .catch((getAppointmentDetailsError) => {
        if (getAppointmentDetailsError.response.status == 401) {
          window.alert("Sorry !! You are unauthorized !!");
          this.$router.push({
            name: "AdminLogin",
          });
        } else if(getAppointmentDetailsError.response.status == 305) {
          window.alert("Sorry this slot has been booked. Please choose another timeslot");
          this.isBookSlot = false;
        } else {
          window.alert("Something went wrong...");
          this.isBookSlot = false;
        }
      });
    },
    getTimeSlot() {
      this.loading = true;
      var date_body = {
        doctorId: this.selected_doctor_id,
        dateOfAppointment: this.selected_date,
        token: this.currentUser,
        typeOfUser: "ADMIN",
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/getTimeSlotDisplay",
          date_body
        )
        .then((getTimeSlotResponse) => {
          this.time_slot = getTimeSlotResponse.data.data;
          // var strtoDate = new Date(getTimeSlotResponse.data.data)
          this.time_slot = this.time_slot.map((timeSlot) => ({
            time_slot: timeSlot.time_slot,
            _id: timeSlot._id,
            customDisabled: (timeSlot.counter <= 0) ||(new Date(this.selected_date+" "+timeSlot.time_slot.split("-")[0]+":00 GMT+0630").getTime() < new Date().getTime()) ? true : false,
            displayTime: timeSlot.displayTime != '' && timeSlot.displayTime != undefined && timeSlot.displayTime != null ? timeSlot.displayTime : timeSlot.time_slot
          }));
        })
        .catch((getTimeSlotError) => {
          if (getTimeSlotError.response.status == 401) {
            this.auth_error_enable = true;
          } else {
            this.error_enable = true;
          }
        });
      this.loading = false;
    },
    closeDialogUnauthorized() {
      this.auth_error_enable = false;
      this.$router.push({
        name: "Login",
      });
    },
    filterPatient() {
      var getPatientData = {
        token: this.currentUser,
        typeOfUser: "ADMIN",
        page: 1,
        limit: 1,
        NRC: this.nrc_number,
        phoneNumber: this.phoneNumber,
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/readAllCustomers",
          getPatientData
        )
        .then((getparticularListResponse) => {
          this.patient_list = getparticularListResponse.data.data;
        })
        .catch((getparticularListError) => {
          if (getparticularListError.response.status == 401) {
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.push({
              name: "AdminLogin",
            });
          } else {
            window.alert("Something went wrong...");
            this.isBookSlot = false;
          }
        });
    },
  },
};
</script>

  <style>
td,
th {
  padding: 10px;
}
#facebook{
  display: none;
}
</style>